<template>
    <div>
        <div class="my-click" v-loading="loadingData">
            <el-row :gutter="20">
                <el-col :span="18">
                    <h3>Hujjatlar</h3>
                </el-col>
            </el-row>
            <el-row :gutter="30">
                <el-col :span="6" v-for="(document, index) in documents" :key="index">
                    <router-link v-if="document.b_files.length > 0" :to="{ name: 'dacument', params: {files: document.b_files} }">
                        <div class="disct">
                            <!-- <div class="son-d">25</div> -->
                            <div class="disct-icon">
                                <img src="./../../../public/img/folder.png" />
                            </div>
                            <div class="name-distc">Fayil: <b>{{document.document_name ? document.document_name : ''}}</b></div>
                        </div>
                    </router-link>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
export default {
    name: "diskClinic",
    computed:{
        ...mapGetters({
            documents: 'clinics/documents'
        }),
    },
    data(){
        return {
            loadingData: false
        }
    },
    created(){
        this.load();
    },
    methods: {
        ...mapActions({
            getDocumentsOfClinic: 'clinics/getDocumentsOfClinic'
        }),
        load(){
            let clinic_id = parseInt(this.$route.params.id);
            if (!this.loadingData) {
                this.loadingData = true;
                this.getDocumentsOfClinic({clinic_id: clinic_id}).then(() => {
                    this.loadingData = false;
                }).catch((err) => {
                    this.loadingData = false;
                });
            }
        }
    }

}
</script>

